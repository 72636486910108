import "./App.css";
import { Suspense, lazy, useRef } from "react";
import { useLoader } from "./hooks/useLoader";
import { useToast } from "./hooks/useToast";
import { setupInterceptors } from "./api/axiosConfig";
import { Route, Routes, useLocation } from "react-router-dom";
import { useAuth, useLogger } from "./hooks";
import LoadingFallback from "./components/common/LoadingFallback/LoadingFallback";
import PrivateRoute from "./components/common/PrivateRoute/PrivateRoute";
import { LogLevel } from "./enums";
import PublicRoute from "./components/common/PublicRoute/PublicRoute";
import { environment } from "./config/environment";

const Header = lazy(() => import("./components/common/Header/Header"));
const Home = lazy(() => import("./components/pages/Home/Home"));
const Login = lazy(() => import("./components/pages/Login/Login"));
const Footer = lazy(() => import("./components/common/Footer/Footer"));
const Dashboard = lazy(
  () => import("./components/pages/Admin/Dashboard/Dashboard")
);
const UserManagement = lazy(
  () => import("./components/pages/Admin/UserManagement/UserManagement")
);
const RoleManagement = lazy(
  () => import("./components/pages/Admin/RoleManagement/RoleManagement")
);
const PasswordPolicy = lazy(
  () => import("./components/pages/Admin/PasswordPolicy/PasswordPolicy")
);
const BillingManagement = lazy(
  () => import("./components/pages/Admin/BillingManagement/BillingManagement")
);
const CustomerManagement = lazy(
  () => import("./components/pages/Admin/CustomerManagement/CustomerManagement")
);
const PosterManagement = lazy(
  () => import("./components/pages/Admin/PosterManagement/PosterManagement")
);
const SideBarMenu = lazy(
  () => import("./components/common/SideBarMenu/SideBarMenu")
);
const ForgotPassword = lazy(
  () => import("./components/pages/Login/ForgotPassword/ForgotPassword")
);
const ResetPassword = lazy(
  () => import("./components/pages/Login/ResetPassword/ResetPassword")
);
const TemplateManagement = lazy(
  () => import("./components/pages/Admin/TemplateManagement/TemplateManagement")
);
const OtpLogin = lazy(
  () => import("./components/pages/Login/OtpLogin/OtpLogin")
);
const OrganizationManagement = lazy(
  () =>
    import(
      "./components/pages/Admin/OrganizationManagement/OrganizationManagement"
    )
);
const UpdateCustomer = lazy(
  () =>
    import(
      "./components/pages/Admin/CustomerManagement/UpdateCustomer/UpdateCustomer"
    )
);
const CategoryManagement = lazy(
  () => import("./components/pages/Admin/CategoryManagement/CategoryManagement")
);
const GalleryManagement = lazy(
  () => import("./components/pages/Admin/GalleryManagement/GalleryManagement")
);
const LeadManagement = lazy(
  () => import("./components/pages/Admin/LeadManagement/LeadManagement")
);

const SupportManagement = lazy(
  () =>
    import(
      "./components/pages/Admin/SupportRequestManagement/SupportRequestManagement"
    )
);

const App = () => {
  const sampleTemplateRef = useRef<HTMLDivElement | null>(null);

  const location = useLocation();
  const { showLoader, hideLoader } = useLoader();
  const { showToast } = useToast();
  const { userToken, isAuthenticated, logout } = useAuth();
  const { setLogLevel, log } = useLogger();

  setLogLevel(LogLevel.INFO);
  setupInterceptors(userToken, logout, showLoader, hideLoader, showToast, log);

  const isFooterHidden = environment.footerHiddenRoutes.includes(
    location.pathname
  );

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Header sampleTemplateRef={sampleTemplateRef} />
      <div className="flex flex-col md:flex-row w-full">
        {isAuthenticated && (
          <aside className="w-full md:w-[20%] h-screen md:block hidden">
            <SideBarMenu />
          </aside>
        )}
        <main
          className={`w-full h-screen ${
            isAuthenticated
              ? "md:w-[80%] sm:w-[100%] xs:w-[100%] pt-16 sm:pt-16 md:pt-14 lg:pt-20 px-2 lg:px-4"
              : ""
          }`}
        >
          <Routes>
            <Route
              path="/"
              element={
                <PublicRoute
                  element={<Home sampleTemplateRef={sampleTemplateRef} />}
                />
              }
            />
            <Route
              path="/login"
              element={<PublicRoute element={<Login />} />}
            />
            <Route
              path="/forgot-password"
              element={<PublicRoute element={<ForgotPassword />} />}
            />
            <Route
              path="/reset-password"
              element={<PublicRoute element={<ResetPassword />} />}
            />
            <Route
              path="/otp-login"
              element={<PublicRoute element={<OtpLogin />} />}
            />
            <Route
              path="/dashboard"
              element={<PrivateRoute element={<Dashboard />} />}
            />
            <Route
              path="/user-management"
              element={<PrivateRoute element={<UserManagement />} />}
            />
            <Route
              path="/role-management"
              element={<PrivateRoute element={<RoleManagement />} />}
            />
            <Route
              path="/password-policy"
              element={<PrivateRoute element={<PasswordPolicy />} />}
            />
            <Route
              path="/billing-configuration"
              element={<PrivateRoute element={<BillingManagement />} />}
            />
            <Route
              path="/customer-management/update-customer"
              element={<PrivateRoute element={<UpdateCustomer />} />}
            />
            <Route
              path="/customer-management"
              element={<PrivateRoute element={<CustomerManagement />} />}
            />
            <Route
              path="/poster-management"
              element={<PrivateRoute element={<PosterManagement />} />}
            />
            <Route
              path="/template-management"
              element={<PrivateRoute element={<TemplateManagement />} />}
            />
            <Route
              path="/organization-management"
              element={<PrivateRoute element={<OrganizationManagement />} />}
            />
            <Route
              path="/categories-management"
              element={<PrivateRoute element={<CategoryManagement />} />}
            />
            <Route
              path="/gallery-management"
              element={<PrivateRoute element={<GalleryManagement />} />}
            />
            <Route
              path="/lead-management"
              element={<PrivateRoute element={<LeadManagement />} />}
            />
            <Route
              path="/support-requests"
              element={<PrivateRoute element={<SupportManagement />} />}
            />
            <Route
              path="*"
              element={
                <PublicRoute
                  element={<Home sampleTemplateRef={sampleTemplateRef} />}
                />
              }
            />            
          </Routes>
        </main>
      </div>
      {!isAuthenticated && !isFooterHidden && <Footer />}
    </Suspense>
  );
};

export default App;
