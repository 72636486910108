import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../hooks';
import { PublicRouteProps } from './publicRouteTypes';

const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();
    
    return !isAuthenticated ? (
        element
    ) : (
        <Navigate to={"/dashboard"} state={{ from: location }} replace/>
    );
};

export default PublicRoute;
